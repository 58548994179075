
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
} from "ant-design-vue";
import { UnorderedListOutlined } from "@ant-design/icons-vue";
import {
  listHoliday,
  disableAreaByCode,
  generateHolidayData,
} from "../../../api/terminalList.js";
import { holidayColumns } from "@/api/fixedData.js";
const { Option } = Select;
export default defineComponent({
  components: {
    Col,
    Row,
    Input,
    Button,
    Select,
    UnorderedListOutlined,
    Table,
    Pagination,
    Popover,
    Option,
    Switch,
  },
  data() {
    return {
      categoryOptions: [],
      brandOptions: [],
      //数据相关
      date: "",
      name: "",
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNumber: 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //拓展字段
      customData: [],
      keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //默认状态
      status: "",
      //默认是否需要上班
      isnotwork: "",
      wage: "",
      daycode: "",
    };
  },
  methods: {
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNumber = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getTerminal();
    },
    //弹出Popover 进行筛选
    handelColumns() {
      (this as any).columns = [...holidayColumns, ...this.keepCustomData];
      (this as any).columns.push({
        title: "状态",
        slots: { customRender: "handle" },
        align: "left",
        fixed: "right",
      });
    },
    inputChange(item) {
      if (item.checked) {
        (this as any).keepCustomData.splice(
          item.key,
          0,
          this.customData[item.key]
        );
      } else {
        for (let i = 0; i < (this as any).keepCustomData.length; i++) {
          if (!(this as any).keepCustomData[i].checked) {
            (this as any).keepCustomData.splice(i, 1);
          }
        }
      }

      //

      this.handelColumns();
    },
    //跳转到终端详情页面
    terminalDetails(date) {
      if (date === undefined) {
        date = "new";
      }
      (this as any).$router.push(`/masterData/holiday/details/${date}`);
    },
    //计算终端状态
    computedStatus(status) {
      switch (status) {
        case false:
          return "禁用";
        case true:
          return "启用";
        default:
          return "系统错误";
      }
    },
    //生成本年度数据
    gen() {
      let date = new Date();
      let y = date.getFullYear();

      generateHolidayData(y)
        .then((res: any) => {
          if (res.data.success) {
            message.success("数据同步成功");
            this.$router.push("/masterData/holiday/list");
            this.getTerminal();
          }
        })
        .catch(() => {
          message.warning("录入信息不规范请修改");
        });
    },
    //获取单品列表
    getTerminal() {
      this.loading = true;
      listHoliday({
        date: this.date,
        name: this.name,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        isnotwork: this.isnotwork,
        daycode: this.daycode,
        wage: this.wage,
      }).then((res: any) => {
        if (res.data.success) {
          // res.data.data.isnotwork==='1'?'是':'否';
          this.total = res.data.total;
          this.data = res.data.data;
          this.loading = false;
        }
        // else{
        //   message.error(res.data.errorMessage)
        // }
      });
    },
    //重置
    reset() {
      this.date = "";
      this.name = "";
      this.isnotwork = "";
      this.wage = "";
      this.daycode = "";
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNumber = 1;
      this.getTerminal();
      this.pageCurrent = 1;
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.date = "";
        this.name = "";
        this.isnotwork = "";
        this.wage = "";
        this.daycode = "";
      }
      this.selectedRowKeys = [];
      this.pageNumber = this.pageCurrent;
      this.getTerminal();
    },
  },
  mounted() {
    // this.tableHeight = window.screen.height - 564 + "px";
    // this.scroll.y = window.screen.height - 600;
    (this as any).$store.commit("changeBreadcrumb", [
      "主数据中心",
      "节假日管理",
      "节假日列表",
    ]);
    //获取列表信息
    this.getTerminal();
    (this as any).columns = holidayColumns;
  },
});
